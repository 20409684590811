import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./Container";

/**
 * Info Sektion mit 2 Paragraphen nebeneinander
 * Mobil: untereinander
 */
const InfoSection = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h2
        style={{ fontFamily: "Bold" }}
        className="text-safiaBlue text-h2mobile sm:text-h2desktop"
      >
        {t("allergenAnalysis")}
      </h2>
      <div className="mt-6 lg:hidden">
        <p>{`${t("allergenAnalysicDesc")}${t("allergenAnalysicDescTwo")}`}</p>
      </div>

      <div className="hidden mt-6 lg:flex flex-row flex-wrap justify-between">
        <div className="w-full sm:w-5/12">
          <p>{t("allergenAnalysicDesc")}</p>
        </div>
        <div className="w-full sm:w-5/12 mt-12 sm:mt-0">
          <p>{t("allergenAnalysicDescTwo")}</p>
        </div>
      </div>
    </Container>
  );
};

export default InfoSection;
