import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import { useMedia } from "react-media";
import Colors from "../utils/colors";
import TriangleRight from "../images/svg/triangleright.svg";
import TriangleRightGreen from "../images/svg/trianglerightgreen.svg";
import TriangleBottom from "../images/svg/triangle-bottom-blue.svg";
import TriangleBottomGreen from "../images/svg/triangle-bottom-green.svg";
import Dots from "../images/svg/timeline-dots.svg";
import CheckIcon from "../images/svg/timeline-check.svg";
import GreenRocketIcon from "../images/svg/green-rocket.svg";
import "./timeline.css";
import MediaQueries from "../utils/MediaQueries";

function Item({ entry, index, arr }) {
  const { t } = useTranslation();
  let currentDate = new Date();
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const passedEntry = currentDate >= entry.date;
  const isFirstElem = index === 0;
  const isLastElem = index === arr.length - 1;

  return (
    <div className="flex flex-row items-center">
      {isFirstElem && (
        <div
          style={{
            height: "5px",
            width: "92px",
            backgroundColor: Colors.screamingGreen,
          }}
        />
      )}

      <div
        className="relative"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "12.5px",
            marginLeft: "11px",
            marginRight: "11px",
          }}
          className={`items-center justify-center flex ${
            passedEntry
              ? "bg-screamingGreen"
              : isMouseEnter
              ? "bg-palatinateBlue"
              : "bg-white"
          }`}
        >
          {passedEntry && <CheckIcon />}
        </div>
        {
          <div
            style={{ left: "-96.5px", bottom: "18px" }}
            className={`absolute items-center flex flex-col hoverItem ${
              isMouseEnter ? "isVisible" : "isHidden"
            }`}
          >
            <div
              style={{ padding: "20px", width: "240px", height: "243px" }}
              className={`${
                passedEntry ? "bg-screamingGreen" : "bg-palatinateBlue"
              }`}
            >
              <h4
                className={`whitespace-pre-wrap mb-4 ${
                  passedEntry ? "text-safiaBlue" : "text-white"
                } text-h4mobile sm:text-h4desktop text-center`}
              >
                {entry.title}
              </h4>
              <p
                className={`text-body text-center ${
                  passedEntry ? "text-safiaBlue" : "text-white"
                }`}
              >
                {entry.text}
              </p>
            </div>
            <div
              style={{ height: "41px", width: "2px" }}
              className={`${
                passedEntry
                  ? "bg-screamingGreen"
                  : isMouseEnter
                  ? "bg-palatinateBlue"
                  : "bg-white"
              }`}
            />
          </div>
        }
        {!isMouseEnter && (
          <div
            style={{
              height: "150px",
              width: "230px",
              left: "-91.5px",
              bottom: "25px",
            }}
            className="absolute flex flex-col items-center justify-between"
          >
            <div className="flex flex-col items-center">
              {index === 2 ? (
                <GreenRocketIcon
                  className="mb-2"
                  style={{ height: "22.44px", width: "21.78px" }}
                />
              ) : (
                <div
                  className="mb-2"
                  style={{ height: "22.44px", width: "21.78px" }}
                />
              )}
              <h4
                className={`whitespace-pre-wrap ${
                  passedEntry ? "text-screamingGreen" : "text-white"
                } text-h4mobile sm:text-h4desktop text-center`}
              >
                {entry.title}
              </h4>
            </div>
            <div
              style={{ height: "41px", width: "2px" }}
              className={`${passedEntry ? "bg-screamingGreen" : "bg-white"}`}
            />
          </div>
        )}

        <div
          style={{
            height: "25px",
            width: "72px",
            left: "-12.5px",
            top: "55px",
          }}
          className="absolute text-white text-body text-center"
        >
          {entry.dateString}
        </div>
      </div>
      {!isLastElem && (
        <div
          className={`relative ${
            currentDate > entry.date && currentDate < arr[index + 1].date
              ? "linearGradientTimeline"
              : !(currentDate >= arr[index + 1].date)
              ? "bg-palatinateBlue"
              : "bg-screamingGreen"
          }`}
          style={{ height: "5px", width: "182px" }}
        >
          {currentDate > entry.date && currentDate < arr[index + 1].date && (
            <div
              style={{ width: "90px", height: "114px", left: "46px" }}
              className="relative items-center justify-between flex flex-col mt-4"
            >
              <Dots />
              <span className="text-white text-center text-body">
                {t("weAreHere")}
              </span>
            </div>
          )}
        </div>
      )}
      {isLastElem && (
        <div className="flex flex-row items-center">
          <div
            style={{
              height: "5px",
              width: "70px",
            }}
            className={`${
              passedEntry ? "bg-screamingGreen" : "bg-palatinateBlue"
            }`}
          />
          {passedEntry ? <TriangleRightGreen /> : <TriangleRight />}
        </div>
      )}
    </div>
  );
}

function ItemMobile({ entry, index, arr, positions }) {
  let currentDate = new Date();
  const passedEntry = currentDate >= entry.date;
  const isFirstElem = index === 0;
  const isLastElem = index === arr.length - 1;
  const matches = useMedia({ queries: MediaQueries });
  const trigger = matches.mobile ? 400 : 900;
  const showMore = positions[index] < trigger;

  return (
    <div className="flex flex-col ml-1/12">
      {isFirstElem && (
        <div
          style={{
            width: "5px",
            height: "76px",
            position: "relative",
            left: 8.5,
            backgroundColor: Colors.screamingGreen,
          }}
        />
      )}
      <div className="flex flex-row items-center">
        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "12.5px",
            marginTop: 10,
            marginBottom: 10,
            flexBasis: 25,
            fleGgrow: 0,
            flexShrink: 0,
          }}
          className={`items-center transitionMobileTimeline justify-center flex ${
            passedEntry
              ? "bg-screamingGreen"
              : passedEntry && showMore
              ? "bg-screamingGreen"
              : !passedEntry && showMore
              ? "bg-palatinateBlue"
              : "bg-white"
          }`}
        >
          {passedEntry && <CheckIcon />}
        </div>
        <div
          style={{ width: "40px", height: "2px" }}
          className={`transitionMobileTimeline ${
            passedEntry
              ? "bg-screamingGreen"
              : showMore
              ? "bg-palatinateBlue"
              : "bg-white"
          }`}
        />
        <div
          className={`transitionMobileTimeline ml-5 w-full px-5 py-4 ${
            showMore
              ? passedEntry
                ? "bg-screamingGreen"
                : "bg-palatinateBlue"
              : "bg-transparent"
          }`}
          ref={entry.reference}
        >
          <h4
            className={`${
              showMore
                ? passedEntry
                  ? "text-safiaBlue"
                  : "text-white"
                : "text-white"
            } text-h4mobile sm:text-h4desktop mb-2`}
          >
            {entry.title}
          </h4>
          {showMore ? (
            <p
              className={`text-body mb-0 ${
                passedEntry ? "text-safiaBlue" : "text-white"
              }`}
            >
              {entry.text}
            </p>
          ) : (
            <div className="text-white text-body">{entry.dateString}</div>
          )}
        </div>
      </div>
      {!isLastElem && (
        <div
          className={`${
            currentDate > entry.date && currentDate < arr[index + 1].date
              ? "linearGradientTimelineMobile"
              : !(currentDate >= arr[index + 1].date)
              ? "bg-palatinateBlue"
              : "bg-screamingGreen"
          }`}
          style={{
            width: "5px",
            height: "126px",
            position: "relative",
            left: 8.5,
          }}
        />
      )}
      {isLastElem && (
        <div style={{ position: "relative", left: 8.5 }}>
          <div
            style={{
              width: "5px",
              height: "80px",
            }}
            className={`${
              passedEntry ? "bg-screamingGreen" : "bg-palatinateBlue"
            }`}
          />
          <div className="rotate-180 relative" style={{ left: -14 }}>
            {passedEntry ? (
              <TriangleBottomGreen className="rotate-90" />
            ) : (
              <TriangleBottom className="rotate-90" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const Timeline = () => {
  const { t } = useTranslation();
  const firstElementRef = useRef();
  const secondElementRef = useRef();
  const thirdElementRef = useRef();
  const fourthElementRef = useRef();
  const fifthElementRef = useRef();
  //const sixElementRef = useRef();
  const [positions, setPositions] = useState([0, 0, 0, 0, 0, 0]);

  function handleScroll() {
    setPositions([
      firstElementRef.current.getBoundingClientRect().y,
      secondElementRef.current.getBoundingClientRect().y,
      thirdElementRef.current.getBoundingClientRect().y,
      fourthElementRef.current.getBoundingClientRect().y,
      fifthElementRef.current.getBoundingClientRect().y,
      // sixElementRef.current.getBoundingClientRect().y,
    ]);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const TIMELINE_DATA = [
    {
      title: t("timeline.first.title"),
      text: t("timeline.first.description"),
      date: new Date(2020, 3),
      dateString: "April 2020",
      reference: firstElementRef,
    },
    {
      title: t("timeline.second.title"),
      text: t("timeline.second.description"),
      date: new Date(2022, 3),
      dateString: `April 2022`,
      reference: secondElementRef,
    },
    {
      title: t("timeline.third.title"),
      text: t("timeline.third.description"),
      date: new Date(2023, 1),
      dateString: `${t("january")} 2023`,
      reference: thirdElementRef,
    },
    {
      title: t("timeline.fourth.title"),
      text: t("timeline.fourth.description"),
      date: new Date(2023, 9),
      dateString: `Q3 2023`,
      reference: fourthElementRef,
    },
    {
      title: t("timeline.fifth.title"),
      text: t("timeline.fifth.description"),
      date: new Date(2024, 9),
      dateString: `${t("october")} 2024`,
      reference: fifthElementRef,
    },
    // {
    //   title: t("timeline.sixth.title"),
    //   text: t("timeline.sixth.description"),
    //   date: new Date(2025, 0),
    //   dateString: `${t("january")} 2025`,
    //   reference: sixElementRef,
    // },
  ];

  return (
    <Container backgroundColor={Colors.safiaBlue}>
      <h2 className="text-h2mobile sm:text-h2desktop mb-20 lg:mb-64">
        {t("timelineMilestones")}
      </h2>
      <div className="hidden xl:flex flex-row  items-center">
        {TIMELINE_DATA.map((entry, index, arr) => (
          <Item arr={arr} index={index} entry={entry} key={entry.title} />
        ))}
      </div>
      <div className="flex flex-col xl:hidden">
        {TIMELINE_DATA.map((entry, index, arr) => (
          <ItemMobile
            arr={arr}
            index={index}
            entry={entry}
            key={entry.title}
            positions={positions}
          />
        ))}
      </div>
    </Container>
  );
};

export default Timeline;
