import React from "react";
import { Link } from "gatsby";
import Container from "./Container";
import Colors from "../utils/colors";

const TitleParagraphButton = ({
  title,
  paragraph,
  onPress,
  buttonName,
  justContactUs,
  secondParagraph,
}) => (
  <Container backgroundColor={Colors.safiaBlue}>
    <h2>{title}</h2>
    <p className="mt-6 w-full lg:w-7/12 text-white">{paragraph}</p>
    {secondParagraph && (
      <p className="mt-4 w-full lg:w-7/12 text-white">
        {secondParagraph} <Link to="/kontakt">{justContactUs}</Link>
      </p>
    )}
  </Container>
);

export default TitleParagraphButton;
