/* eslint-disable import/first */
import React, { useState, useRef, useEffect } from "react";
import Particles from "react-tsparticles";
// import Slider from "react-slick";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import scrollTo from "gatsby-plugin-smoothscroll";

const throttle = require("lodash/throttle");

import ParticleConfig from "../particles/particleconfigSubpage";
import ParticleConfigMobile from "../particles/particleconfigSubpageMobile";
import NewsletterSubscribe from "../components/NewsletterSubscribe";
import ContactUs from "../components/ContactUs";
import Cytometry from "../images/svg/cytometry.svg";
import SafiaSoftware from "../images/svg/safia-software.svg";
import SafiaService from "../images/svg/safia-service.svg";
import SafiaKitWater from "../images/svg/safia-kit-water.svg";
import SafiaKitMycotoxin from "../images/svg/safia-kit-mycotoxin.svg";
import SafiaKitAllergy from "../images/svg/safia-kit-allergy.svg";
import PrevArrow from "../images/svg/prevArrow.svg";
import NextArrow from "../images/svg/nextArrow.svg";
import ScrollDownButton from "../components/ScrollDownButton";
import Container from "../components/Container";
import Timeline from "../components/Timeline";
import InfoSection from "../components/InfoSection";
import TitleParagraphButton from "../components/TitleParagraphButton";
import useDeviceDetect from "../components/useDeviceDetect";

import "../css/carousel.min.css";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IconWrapper = ({ children }) => (
  <div
    className="flex items-center justify-center text-center"
    style={{ height: "100px", width: "110px" }}
  >
    {children}
  </div>
);

const ProductPage = () => {
  console.log("PRODUCT");
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const particleDiv = useRef(null);
  const [particleHeight, setParticleHeight] = useState(973 + 72);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const resize = throttle(() => {
      setParticleHeight(particleDiv.current.clientHeight + 72);
    }, 200);

    setParticleHeight(particleDiv.current.clientHeight + 72);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [particleDiv]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (current) => setCurrentSlide(current),
  };

  function getTextByCurrentSlide(current) {
    if (current === 0) {
      return (
        <span>
          {t("kitMykotocinDesc1")}{" "}
          <a onClick={() => scrollTo("#schimmelpilzgifteHref")}>
            {t("schimmelpilzgifteHref")}
          </a>
          .
        </span>
      );
    } else if (current === 1) {
      return (
        <span>
          {t("kitAllergenDesc1")}{" "}
          <a onClick={() => scrollTo("#allergenenHref")}>
            {t("allergenenHref")}
          </a>
          .
        </span>
      );
    }
    return (
      <span>
        {t("kitUmweltDesc1")}{" "}
        <a onClick={() => scrollTo("#umweltanalytikHref")}>
          {t("umweltanalytikHref")}
        </a>{" "}
        {t("kitUmweltDesc2")}
      </span>
    );
  }

  return (
    <Layout>
      <SEO
        title={t("product")}
        meta={[
          {
            name: "cache-control",
            content: "public, max-age=0, must-revalidate",
          },
        ]}
      />
      <div
        style={{
          position: "relative",
          top: 0,
          left: 0,
        }}
      >
        <Particles
          height={particleHeight}
          className="bg-safiaBlue"
          params={isMobile ? ParticleConfigMobile : ParticleConfig}
        />
        <div ref={particleDiv} className="absolute top-0 w-full">
          <div className="container mx-auto px-8">
            <div style={{ paddingTop: "152px", paddingBottom: "80px" }}>
              <h1 className="w-full lg:w-3/4 text-h1mobile sm:text-h1desktop">
                {t("safiaSystem")}
              </h1>
              <h2
                style={{ fontFamily: "Regular", marginTop: "30px" }}
                className="text-body sm:text-h2desktop w-full sm:w-4/5"
              >
                {t("readoutDeviceDesc")}
              </h2>
              <div className="flex flex-row mt-18 text-center place-content-center flex-wrap lg:flex-no-wrap">
                <div className="flex flex-col items-center w-full lg:w-240px lg:mr-6 mb-20 lg:mb-0">
                  <div className="flex flex-col items-center">
                    <div style={{ maxWidth: 246, height: 100 }}>
                      <Carousel
                        infiniteLoop
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        stopOnHover
                        onChange={(idx, item) => setCurrentSlide(idx)}
                      >
                        <div
                          style={{ height: "100px", width: "110px" }}
                          className="mx-auto"
                        >
                          <SafiaKitMycotoxin
                            style={{
                              height: "98px",
                              width: "92px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </div>
                        <div
                          style={{ height: "100px", width: "110px" }}
                          className="mx-auto"
                        >
                          <SafiaKitAllergy
                            style={{
                              height: "98px",
                              width: "92px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </div>
                        <div
                          style={{ height: "100px", width: "110px" }}
                          className="mx-auto"
                        >
                          <SafiaKitWater
                            style={{
                              height: "98px",
                              width: "92px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </div>
                      </Carousel>
                    </div>

                    {/* <Slider
                      style={{ maxWidth: 246, height: 100 }}
                      {...settings}
                    >
                      <div
                        style={{ height: "100px", width: "110px" }}
                        className="mx-auto"
                      >
                        <SafiaKitMycotoxin
                          style={{
                            height: "98px",
                            width: "92px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      </div>
                      <div
                        style={{ height: "100px", width: "110px" }}
                        className="mx-auto"
                      >
                        <SafiaKitAllergy
                          style={{
                            height: "98px",
                            width: "92px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      </div>
                      <div
                        style={{ height: "100px", width: "110px" }}
                        className="mx-auto"
                      >
                        <SafiaKitWater
                          style={{
                            height: "98px",
                            width: "92px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      </div>
                    </Slider> */}
                    <h3 className="mt-11 mb-6 text-white">{t("safiakit")}</h3>
                    <p className="text-white text-center w-9/12 lg:w-full">
                      {getTextByCurrentSlide(currentSlide)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center w-full lg:w-240px lg:mr-6 mb-20 lg:mb-0">
                  <IconWrapper>
                    <Cytometry style={{ height: "98px", width: "92px" }} />
                  </IconWrapper>
                  <h3 className="mt-11 mb-6 text-white">
                    {t("readoutDevice")}
                  </h3>
                  <p className="text-white w-9/12 lg:w-full">
                    {t("readoutDeviceDescBenchTop")}
                  </p>
                </div>
                <div className="flex flex-col items-center w-full lg:w-240px lg:mr-6 mb-20 lg:mb-0">
                  <IconWrapper>
                    <SafiaSoftware style={{ height: "88px", width: "101px" }} />
                  </IconWrapper>
                  <h3 className="mt-11 mb-6 text-white">SAFIA Software</h3>
                  <p className="text-white w-9/12 lg:w-full">
                    {t("softwareDesc")}
                  </p>
                </div>
                <div className="flex flex-col items-center w-full lg:w-240px">
                  <IconWrapper>
                    <SafiaService style={{ height: "91px", width: "91px" }} />
                  </IconWrapper>
                  <h3 className="mt-11 mb-6 text-white">Support & Service</h3>
                  <p className="text-white w-9/12 lg:w-full">
                    {t("supportAndServiceDesc")}
                  </p>
                </div>
              </div>
              <ScrollDownButton selector="#secondsection" marginTop="mt-8" />
            </div>
          </div>
        </div>
        <div id="secondsection">
          <Container backgroundColor="#fff">
            <div className="flex flex-row flex-wrap">
              <div className="w-full md:w-5/12">
                <h2 className="text-safiaBlue text-h2mobile sm:text-h2desktop md:h-24">
                  {t("whyIsSafiaBetter")}
                </h2>
                <p className="mt-6">{t("whyIsSafiaBetterDesc")}</p>
              </div>
              <div className="w-full mt-12 sm:mt-0 md:w-5/12 md:ml-20 lg:ml-28">
                <h2 className="text-safiaBlue text-h2mobile sm:text-h2desktop md:h-24">
                  {t("applicationAreas")}
                </h2>
                <p className="mt-6">{t("applicationAreasDesc")}</p>
              </div>
            </div>
          </Container>
        </div>
        <Timeline />
        <div id="schimmelpilzgifteHref">
          <TitleParagraphButton
            title={t("mycotoxinAnalysis")}
            paragraph={t("mycotoxinAnalysisDesc")}
            secondParagraph={`${t("mycotoxinAnalysisDescSecond")}\n\n${t(
              "mycotoxinAnalysisContact"
            )}`}
            buttonName={t("moreInformation")}
            justContactUs={t("justContactUs")}
            onPress={() =>
              window.open(
                "mailto:info@safia.de?subject=Anfrage Mykotoxinanalytik"
              )
            }
          />
        </div>
        <div id="allergenenHref">
          <InfoSection />
        </div>
        <div id="umweltanalytikHref">
          <TitleParagraphButton
            title={t("environmentalAnalysis")}
            paragraph={t("environmentalAnalysisDesc")}
            secondParagraph={t("environmentalAnalysisContact")}
            justContactUs={t("justContactUs")}
            buttonName={t("moreInformation")}
            onPress={() =>
              window.open("mailto:info@safia.de?subject=Anfrage Umweltanalytik")
            }
          />
        </div>
        <ContactUs />
        {/* <NewsletterSubscribe /> */}
      </div>
    </Layout>
  );
};

export default ProductPage;
